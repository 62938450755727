import React, { Dispatch, SetStateAction, useState } from 'react'
import { graphql } from 'gatsby'
import SEO from '../../components/SEO'
import Accordion from '../../components/Accordion'
import {
  ContentfulFeatureContent,
  ContentfulList,
  ContentfulMedia,
  ContentfulPage,
  ContentfulProduct,
  ContentfulText,
} from '../../../types/graphql-types'
import HowToCards from '../../components/HowTo/Cards'
import {
  HeroApp,
  ContentSection,
  DownLoadsWrap,
  DownLoads,
  AccordionStyled,
  AccordionTitle,
  AccordionCarrot,
  CollapseBody,
} from '../../styles/support-styles'
import LayoutBlank from '../../components/Layout/Blank'

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

export const query = graphql`
  query SupportLW2App {
    contentfulPage(externalName: { eq: "Lively Wearable2 Support Page" }) {
      ...supportDetailsPage
    }
  }
`

const SupportLW2AppPage: React.FC<Props> = ({ data }: Props) => {
  // Get product
  const getProduct = (
    data.contentfulPage.references as ContentfulProduct[]
  ).find(product => product.deviceName === 'Lively Wearable2')

  // Get FAQ list
  const getFaqList = (getProduct?.faQs as ContentfulList[]).find(
    faqs => faqs.externalName === 'FAQs'
  )

  // Get Open FAQ radio button
  const openFaq = getFaqList?.openFaq

  // Get first FAQ
  const getFaq = (getFaqList?.items as ContentfulText[]).find(
    faq => faq.externalName === 'Faq'
  )

  // Get first FAQ ID
  const indexFaq = getFaq?.id

  {
    /* If Open FAQ is set to true then indexFaq will be 
    first FAQ Id otherwise it will be set to empty string.*/
  }
  const [activeEventKey, setActiveEventKey] = useState(
    openFaq === true ? `${indexFaq}` : ''
  )

  const [moreFaqs, setMoreFaqs] = useState(false)

  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    ref.current && ref.current.focus()
  }, [ref.current])

  const showMoreFaqs = () => {
    setMoreFaqs(!moreFaqs)
  }

  const deviceName = 'Lively Wearable2'

  return (
    <LayoutBlank>
      <SEO
        title={data.contentfulPage?.seoTitle as string}
        description={data.contentfulPage?.seoDescription as string}
        isSupportDetailsPage
        deviceName={deviceName}
        noIndex={true}
      />
      <ContentSection className="container pt-8">
        {(data.contentfulPage.references as ContentfulFeatureContent[]).map(
          hero => {
            if (hero.name === 'Lively Wearable2 Support Hero') {
              return (
                <HeroApp key={hero.id} className={`container`}>
                  {(hero.references as ContentfulText[]).map(heroCopy => {
                    if (
                      heroCopy.name === 'Lively Wearable2 Support Hero Copy'
                    ) {
                      return (
                        <div
                          key={heroCopy.id}
                          dangerouslySetInnerHTML={{
                            __html: heroCopy.longSimpleText?.childMarkdownRemark
                              ?.html as string,
                          }}
                        ></div>
                      )
                    }
                  })}
                </HeroApp>
              )
            }
          }
        )}
        {(data.contentfulPage.references as ContentfulText[]).map(intro => {
          if (intro.name === 'Support Detail Intro') {
            return (
              <div key={intro.id} className="grid-cols-12 md:grid">
                <h2 className="col-start-1 mb-4 border-none md:col-end-7 lg:col-end-6 xl:col-end-5">
                  {intro.shortSimpleText}
                </h2>
                <div
                  className="md:col-start-7 md:col-end-13 lg:col-start-6 xl:col-start-5 xl:col-end-12"
                  dangerouslySetInnerHTML={{
                    __html: intro.longSimpleText?.childMarkdownRemark
                      ?.html as string,
                  }}
                ></div>
              </div>
            )
          }
        })}
      </ContentSection>
      <ContentSection className="content-section">
        <h2>Downloads</h2>
        {(data.contentfulPage.references as ContentfulProduct[]).map(
          product => {
            return (
              <DownLoadsWrap key={product.id}>
                {(product.guides as ContentfulMedia[])?.map(guide => {
                  return (
                    <DownLoads key={guide.id}>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={guide.media?.localFile?.publicURL as string}
                      >
                        <span className="icon-lively-download text-3xl"></span>
                        <p>{guide.name}</p>
                      </a>
                    </DownLoads>
                  )
                })}
              </DownLoadsWrap>
            )
          }
        )}
      </ContentSection>
      <ContentSection className="content-section">
        <HowToCards data={data} />
      </ContentSection>
      <ContentSection className="content-section-py">
        <h2>Frequently Asked Questions</h2>
        {(data.contentfulPage.references as ContentfulProduct[]).map(
          product => {
            if (product.internal?.type === 'ContentfulProduct') {
              return (
                <Accordion
                  activeEventKey={activeEventKey}
                  onToggle={
                    setActiveEventKey as unknown as Dispatch<
                      SetStateAction<string | undefined>
                    >
                  }
                  key={product?.id}
                  className={''}
                >
                  {(product.faQs as ContentfulList[]).map(list => {
                    if (list.externalName === 'FAQs') {
                      return (
                        <React.Fragment key={list.id}>
                          {(list.items as ContentfulText[]).map(
                            (faq, index) => {
                              const props =
                                index === 5 ? { ref: ref, tabIndex: -1 } : {}
                              return (
                                <AccordionStyled
                                  key={faq?.id}
                                  className={`${
                                    !moreFaqs && index > 4 ? 'hidden' : ''
                                  }`}
                                  {...props}
                                >
                                  <Accordion.Toggle
                                    onClick={(
                                      event: React.MouseEvent<
                                        HTMLButtonElement,
                                        MouseEvent
                                      >
                                    ) => void event}
                                    data-analytics-faq={faq?.name}
                                    eventKey={faq?.id}
                                    aria-controls={`${faq?.id}-controls`}
                                    aria-expanded={
                                      activeEventKey === faq?.id ? true : false
                                    }
                                    id={faq?.id}
                                    className="flex w-full items-center justify-between p-4"
                                  >
                                    <AccordionTitle>
                                      <div>
                                        <p>{faq?.name}</p>
                                      </div>
                                    </AccordionTitle>
                                    <AccordionCarrot
                                      className={`icon-angle-down`}
                                    ></AccordionCarrot>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse
                                    eventKey={faq?.id}
                                    role="region"
                                    aria-labelledby={faq?.id}
                                    id={`${faq?.id}-controls`}
                                  >
                                    <CollapseBody
                                      className="px-4"
                                      dangerouslySetInnerHTML={{
                                        __html: faq.longSimpleText
                                          ?.childMarkdownRemark?.html as string,
                                      }}
                                    ></CollapseBody>
                                  </Accordion.Collapse>
                                </AccordionStyled>
                              )
                            }
                          )}
                        </React.Fragment>
                      )
                    }
                  })}
                </Accordion>
              )
            }
          }
        )}
        <button className="btn-secondary" onClick={showMoreFaqs}>
          {!moreFaqs ? 'Show More' : 'Show Less'}
        </button>
      </ContentSection>
    </LayoutBlank>
  )
}
export default SupportLW2AppPage
